<template>
  <div :key="updateKey">
    <request-section
      :requests="requests"
      @setPage="getRequests"
      @openTeamDialog="openTeamDialog"
      @openProjectDialog="openProjectDialog"
    />
    <team-dialog
      v-model="teamDialog"
      :team-id="selectedTeam"
      @openProjectDialog="openProjectDialog"
    />

    <project-dialog v-model="projectDialog" :project-id="selectedProject" />
  </div>
</template>
<script>
import { RequestSection } from "@/components/contact/sections";
import TeamDialog from "@/components/dialogs/TeamDialog";
import ProjectDialog from "@/components/dialogs/ProjectDialog";
import { initialListingModel } from "@/utils";
import { talentClient } from "@/api";
export default {
  name: "ContactRequestsPage",
  components: {
    RequestSection,
    ProjectDialog,
    TeamDialog,
  },
  data() {
    return {
      requests: initialListingModel(15, { ordering: "-updated_at" }),
      teamDialog: false,
      selectedTeam: null,
      selectedProject: null,
      projectDialog: false,
      updateKey: 0,
    };
  },
  metaInfo() {
    return {
      title: `Карточка контакта / Заявки`,
    };
  },
  computed: {
    talentId() {
      return +this.$route.params.id;
    },
  },
  activated() {
    this.getRequests(this.requests.page);
    this.updateKey += 1;
  },
  methods: {
    async getRequests(page = 1) {
      if (this.requests.pending) return;
      this.requests.pending = true;
      this.requests.error = "";
      const { talentId } = this;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `/users/${talentId}/requests/`,
          params: {
            limit: this.requests.limit,
            offset: (page - 1) * this.requests.limit,
            ...this.requests.params,
          },
        });
        this.requests = {
          ...this.requests,
          list: data.results,
          total: data.count,
          pagesCount: Math.ceil(data.count / this.requests.limit),
          page,
        };
      } catch (error) {
        this.requests.error = error.message;
      }
      this.requests.pending = false;
    },
    openTeamDialog(teamId) {
      this.selectedTeam = teamId;
      this.teamDialog = true;
    },
    openProjectDialog(projectId) {
      this.selectedProject = projectId;
      this.projectDialog = true;
    },
  },
};
</script>
